import { MouseEvent, ChangeEvent } from 'react';
import { intervalToDuration, formatDuration, formatDistanceToNow, parse, isValid, format, addDays, isBefore, isSameDay, subDays, endOfDay, startOfDay } from 'date-fns';
import { ptBR, enUS as en } from 'date-fns/locale';
import html2canvas from 'html2canvas';
import { Row } from '@/hooks/articles/useArticle';
import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const getNameAbbr = (name: string, defaultName: string = ''): string => {
  if(!name || typeof name !== 'string'){
    return defaultName
  }

  const [firstName, lastName] = name.split(" ");
  if (!lastName) {
    return firstName.charAt(0).toUpperCase();
  }

  return `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`;
}

export const isValidEmail = (email: string): boolean => {
  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  return emailRegex.test(email);
};

export const sleep = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const getItemIndex = (item: any, sourceArray: any[]) => sourceArray.findIndex(s => s.id === item.id) || 0;

export const stringToNumber = (text: string = ''): number => Number(text.replaceAll('_', '').trim());

export const getUser = (id: string, users: any) => {
  const userInfo = users?.find((u: any) => u.userId === id)
  return getUserName(userInfo);
}

export const getUserInitials = (id: string, users: any, isInitial: boolean = false) => {
  const userInfo = users?.find((u: any) => u.userId === id)
  return getUserName(userInfo, isInitial);
}

type UserInfoProp = { 
  first_name: string;
  last_name?: string;
}

export const getUserName = (userInfo: UserInfoProp, initials: boolean = false): string => {
  if (!userInfo) return '';

  const { first_name, last_name = '' } = userInfo;

  if (!first_name && !last_name) return ''

  if (initials) {
    return `${first_name.charAt(0)}${last_name.charAt(0)}`.trim();
  } else {
    return `${first_name} ${last_name}`.trim();
  }
}

export const getUserFullName = (userName: string, initials?: boolean): string => {
  const [first_name, last_name = ''] = userName.split(" ");

  if (!first_name && !last_name) { return ''; }

  if (initials) {
    return `${first_name && first_name[0]}${last_name && last_name[0]}`.trim();
  } else {
    return `${first_name} ${last_name}`.trim();
  }
};

export const getUserPhoto = (id: string, users: any) => {
  return (users?.find((u: any) => u.userId === id))?.photo || null
}

export function reorder<T>(arrayToReorder: T[], startIndex: number, endIndex: number): T[] {
  const result = Array.from(arrayToReorder);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
}

export const handlePreventDefault = (e: MouseEvent<HTMLElement>) => {
  e.preventDefault()
  e.stopPropagation()
}

export const dateToString = (date: Date): string => {
  const inputDate = new Date(date);

  const day = inputDate.getDate();
  const month = inputDate.getMonth() + 1;
  const year = inputDate.getFullYear();
  const hours = inputDate.getHours();
  const minutes = inputDate.getMinutes();

  const formattedDateString = `${String(day).padStart(2, '0')}/${String(month).padStart(2, '0')}/${year} ${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;

  return formattedDateString
}

export const formatStringDate = (date: string): string | undefined => {
  if(!date){ return }
  const inputDate = date.split(" ");
  const dateToInvert = inputDate[0].split("-");

  return `${dateToInvert[2]}/${dateToInvert[1]}/${dateToInvert[0]} ${inputDate[1]}`
}

export const trimWhiteSpacesOnly = (input: string = ''): string => {
  if(/^\s*$/.test(input)){
    return input.trim();
  }

  return input;
}

export const diffForHumans = (date: Date | string, addSuffix: boolean = true, lang?: string): string => { 
  const convertedDate: Date = new Date(date)
  const methodLang = lang === "en" ? en : ptBR

  if (isValid(convertedDate)) {
    return formatDistanceToNow(convertedDate, { locale: methodLang, addSuffix });
  } else {
    return 'Data Inválida';
  }
}

export const dateFormat = (date: Date, formatString: string): string => { 
  const convertedDate = new Date(date);

  if (isValid(convertedDate)) {
    return format(convertedDate, formatString);
  } else {
    return 'Data Inválida';
  }
}

export const parseDuration = (durationString) => {
  if(!durationString){return}
  const [hours, minutes, secondsWithMillis] = durationString?.split(':');
  const [seconds, milliseconds] = secondsWithMillis?.split('.');

  const duration = parse(`${hours}:${minutes}:${seconds}`, 'HH:mm:ss', new Date(0, 0, 0));
  return duration.getSeconds() + parseFloat(`0.${milliseconds}`);
};

export const simulateEvent = (value: string): ChangeEvent<HTMLInputElement> => ({
  target: {
    value,
    name: 'title', 
  } as HTMLInputElement,
  currentTarget: {
    value,
    name: 'title',
  } as HTMLInputElement,
  persist: () => {},
} as ChangeEvent<HTMLInputElement>);

export const truncateString = (str: string, maxLength: number) => {
  if (str.length > maxLength) {
    return str.slice(0, maxLength);
  }
  return str;
}

export function* cyclicTextGenerator(messages: string[]) {
  let index = 0;

  while (true) {
    yield messages[index];
    index = (index + 1) % messages.length;
  }
}

export function debounce(func, timeout = 300){
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}

export const takeScreenshot = (divId, fileName) => {
  const element = document.getElementById(divId);

  if(element){
    html2canvas(element).then((canvas) => {
      const link = document.createElement('a');
      link.href = canvas.toDataURL();
      link.download = fileName || 'screenshot.png';
      link.click();
    })
  }
};

export const convert2Base64 = (file) => {
  const reader = new FileReader();

  reader.onloadend = () => {
    return reader?.result?.toString()
  }

  reader.readAsDataURL(file)
}

export const isAbsolutePath = (path: string) => {
  return /^(https?:\/\/|data:)/i.test(path);
};

export const todayDate = (lang?: string) => {
  const methodLang = lang === "en" ? en : ptBR
  const today = new Date();
  const dayOfToday = format(today, 'dd', { locale: methodLang });
  const monthOfToday = format(today, 'MMMM', { locale: methodLang });
  const dayOfWeek = format(today, 'EEEE', { locale: methodLang });
  const currentHour = today.getHours();
  
  let greeting = "";
  if (currentHour >= 5 && currentHour < 12) {
    greeting = "Bom dia";
  } else if (currentHour >= 12 && currentHour < 18) {
    greeting = "Boa tarde"; 
  } else {
    greeting = "Boa noite";
  }

  return { dayOfToday, monthOfToday, dayOfWeek, greeting }
}

export const testRemoteImage = (url: string): Promise<boolean> => {
  return new Promise((resolve) => {
    const img = new Image()

    img.onload = function() {
      resolve(true)
    }

    img.onerror = function() {
      resolve(false)
    }
    
    img.src = url
  })
}

export const avgMinutes = (minutes) => {
  const totalMinutes = parseFloat(minutes)

  const hours = Math.floor(totalMinutes / 60)
  const remainingMinutes = Math.floor(totalMinutes % 60)
  const seconds = Math.round((totalMinutes % 1) * 60)

  return {
    hours: hours,
    minutes: remainingMinutes,
    seconds: seconds
  }
}

export const generateDateArray = (start: Date, end: Date) => {
  const dates: string[] = [];
  let currentDate: Date = start;

  while (isBefore(currentDate, end) || isSameDay(currentDate, end)) {
    dates.push(format(currentDate, 'yyyy-MM-dd'));
    currentDate = addDays(currentDate, 1);
  }
  return dates;
}

interface ISliced {
  sliceName: string
  autopilot: number
  copilot: number
  total: number
}

export const fullSlicedData = (sliced: ISliced[], startDate: Date, endDate: Date) => {
  // const start = addDays(startDate, 1);
  const dateArray = generateDateArray(startDate, endDate);

  const newSlicedData = dateArray.map(date => {
    const slice = sliced?.find(item => item.sliceName === date);
    if (slice) {
      return slice;
    } else {
      return { sliceName: date, autopilot: 0, copilot: 0, total: 0 };
    }
  });
  
  return newSlicedData;
}

interface ISlicedDataMessage {
  label: string
  autopilot: {
    total: number
  }
  copilot: {
    total: number
  }
  total: number
}

export const fullSlicedDataMessage = (sliced: ISlicedDataMessage[], startDate: Date, endDate: Date) => {
  // const start = addDays(startDate, 1);
  const dateArray = generateDateArray(startDate, endDate);

  const newSlicedData = dateArray.map(date => {
    const slice = sliced?.find((item: ISlicedDataMessage) => item?.label === date);
    if (slice) {
      return {
        label: slice.label,
        autopilot: slice.autopilot.total,
        copilot: slice.copilot.total,
        total: Number(slice.autopilot.total) + Number(slice.copilot.total)
      };
    } else {
      return { label: date, autopilot: 0, copilot: 0, total: 0 };
    }
  });
  
  return newSlicedData;
}

interface DateInterval {
  startDate: Date
  endDate: Date
  formattedStartDate: string
  formattedEndDate: string
}

export const getDateIntervalByTotalOfDays = (totalDays: number): DateInterval => {
  const currentDate = new Date();
  const endDate = endOfDay(currentDate);
  const startDate = subDays(startOfDay(currentDate), totalDays);

  return { 
    startDate, 
    endDate,  
    formattedStartDate: format(startDate, 'yyyy-MM-dd HH:mm:ss'),
    formattedEndDate: format(endDate, 'yyyy-MM-dd HH:mm:ss') 
  };
}

export function parseTimeString(timeString: string | null) {
  if (!timeString) return '';

  const [timePart, millisecondsPart] = timeString.split('.');
  const [hours, minutes, seconds] = timePart.split(':').map(Number);

  let totalMilliseconds = (hours * 3600 + minutes * 60 + seconds) * 1000;

  if (millisecondsPart && parseInt(millisecondsPart.substring(0, 3)) >= 500) {
    totalMilliseconds += 1000;
  }

  const duration = intervalToDuration({ start: 0, end: totalMilliseconds });
  const responseTime = formatDuration(duration, { format: ['hours', 'minutes', 'seconds'], locale: ptBR });

  return responseTime;
}

function getMonthAbbreviation(date) {
  return format(date, 'MMM', { locale: ptBR });
}

export const formatDateRange = (startDate: Date, endDate: Date) => {
  const startDay = format(startDate, 'dd')
  const startMonthAbbrev = getMonthAbbreviation(startDate);

  const endDay = format(endDate, 'dd');
  const endMonthAbbrev = getMonthAbbreviation(endDate);

  return `${startDay} ${startMonthAbbrev} - ${endDay} ${endMonthAbbrev}`;
}

export const getDateIntervalString = (startDate: Date, endDate: Date): string => {
  const formattedStartDate = format(startDate, 'd LLL', { locale: ptBR });
  const formattedEndDate = format(endDate, 'd LLL', { locale: ptBR });

  return `${formattedStartDate} - ${formattedEndDate}`;
}

export const convertArrayObjToString = (data: Row[]) => data?.map((el: Row) => el.value) || []

const badTitles = new Set(['ola', 'olá', 'olá!', 'oi', 'bom dia', 'boa tarde', 'boa noite'])
export const isBadTitle = (input: string): boolean => badTitles.has(input.toLocaleLowerCase())

export const getPercent = (total: number, valueToCheck: number) => {
  if (total === 0) {
    return 0;
  }

  return Math.round((Number(valueToCheck) / total) * 100);
}

export function markdownToHtml(markdown: string): string {
  // Headers
  markdown = markdown.replace(/^###### (.*$)/gim, '<h6 style="margin: 5px 0 10px 0;">$1</h6>');
  markdown = markdown.replace(/^##### (.*$)/gim, '<h5 style="margin: 5px 0 10px 0;">$1</h5>');
  markdown = markdown.replace(/^#### (.*$)/gim, '<h4 style="margin: 5px 0 10px 0;">$1</h4>');
  markdown = markdown.replace(/^### (.*$)/gim, '<h3 style="margin: 5px 0 10px 0;">$1</h3>');
  markdown = markdown.replace(/^## (.*$)/gim, '<h2 style="margin: 5px 0 10px 0;">$1</h2>');
  markdown = markdown.replace(/^# (.*$)/gim, '<h1 style="margin: 5px 0 10px 0;">$1</h1>');

  // Bold and Italic
  markdown = markdown.replace(/\*\*(.*?)\*\*/gim, '<strong style="font-weight: 600">$1</strong>');
  markdown = markdown.replace(/\*(.*?)\*/gim, '<em>$1</em>');

  // Inline Code
  markdown = markdown.replace(/`([^`]+)`/gim, '<code>$1</code>');

  // Links
  markdown = markdown.replace(/\[([^\]]+)\]\(([^)]+)\)/gim, '<a href="$2" target="_blank" title="Clique para abrir o link" style="margin-bottom: 10px">$1</a>');
  
  // Images
  markdown = markdown.replace(/!\[([^\]]*)\]\(([^)]+)\)/gim, '<img src="$2" alt="$1">');

  markdown = markdown.replace(/^\d+\.\s(.*$)/gim, '<li style="margin-bottom: 10px">$1</li>');
  markdown = markdown.replace(/(<li style="margin-bottom: 10px">.*<\/li>)/gim, '<ol style="padding: 5px 0 5px 15px">$1</ol>');
  markdown = markdown.replace(/<\/ol>\n<ol style="padding: 5px 0 5px 15px">/gim, '');

  // Unordered Lists (grouping list items)
  markdown = markdown.replace(/^\-\s(.*$)/gim, '<li style="margin-bottom: 10px">$1</li>');
  markdown = markdown.replace(/(<li style="margin-bottom: 10px">.*<\/li>)/gim, '<ul style="padding: 5px 0 5px 15px">$1</ul>');
  markdown = markdown.replace(/<\/ul>\n<ul style="padding: 5px 0 5px 15px">/gim, '');

  // Code Blocks
  markdown = markdown.replace(/```(.*?)```/gs, '<pre style="margin-bottom: 10px"><code>$1</code></pre>');

  // Blockquotes
  markdown = markdown.replace(/^> (.*$)/gim, '<blockquote style="margin-bottom: 10px">$1</blockquote>');

  // Horizontal Rules
  markdown = markdown.replace(/^\-\-\-$/gim, '<hr style="margin: 10px 0">');

  // Paragraphs (handle new lines)
  markdown = markdown.replace(/\n\n+/gim, '</p><p>');
  markdown = '<p style="margin-bottom: 10px">' + markdown + '</p>';

  // Clean up empty paragraphs
  markdown = markdown.replace(/<p>\s*<\/p>/g, '');

  return markdown;
}

export function isMarkdown(text: string): boolean {
  // Check for Markdown header patterns
  const headerPattern = /^(#{1,6} .+|[-*+]\s.+)$/gm;

  // Check for bold text patterns
  const boldPattern = /\*\*.+\*\*/g;

  // Check for italic text patterns
  const italicPattern = /\*.+\*/g;

  // Check for inline code patterns
  const codePattern = /`[^`]+`/g;

  // Check for links
  const linkPattern = /\[.+\]\(https?:\/\/[^\)]+\)/g;

  // Check for images
  const imagePattern = /!\[.*\]\(https?:\/\/[^\)]+\)/g;

  // Check for ordered lists
  const orderedListPattern = /^\d+\.\s.+$/gm;

  // Check for unordered lists
  const unorderedListPattern = /^\s*[-*+]\s.+$/gm;

  // Check for code blocks
  const codeBlockPattern = /```[^`]+```/gs;

  // Check for blockquotes
  const blockquotePattern = /^> .+$/gm;

  // Check for horizontal rules
  const horizontalRulePattern = /^-{3,}$/gm;

  // Check if any Markdown pattern is found in the text
  return headerPattern.test(text) ||
         boldPattern.test(text) ||
         italicPattern.test(text) ||
         codePattern.test(text) ||
         linkPattern.test(text) ||
         imagePattern.test(text) ||
         orderedListPattern.test(text) ||
         unorderedListPattern.test(text) ||
         codeBlockPattern.test(text) ||
         blockquotePattern.test(text) ||
         horizontalRulePattern.test(text);
}

export const isSelected = (id: string, arrayToCheck: unknown[]) => arrayToCheck.indexOf(id) !== -1;

export function sanitizePhoneNumber(phoneNumber: string) {
  if (!phoneNumber) return '';
  return phoneNumber
    .replace(/[\s()\-+]/g, '')
    .trim();
}

export function sanitizeString(text: string) {
  if (!text) return '';
  const normalizedText: string = text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  return normalizedText
    .replace(/\s+/g, '_')
    .toLowerCase()
    .replace(/[^a-z0-9_]/g, '')
    .trim();
}

export const minutesToMs = (minutes: number): number => minutes * 60 * 1000;

export const safeNumber = (value) => (typeof value === 'number' ? value : parseFloat(value) || 0);