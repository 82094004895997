import * as React from "react"
import SvgIcon from '@mui/material/SvgIcon';

type IIconProps = {
  className?: string
  width?: number
  height?: number
}

export const HeadSetIcon = ({ className, width, height }: IIconProps, ...props: any) => 
  <SvgIcon 
    width={width || 23}
    height={height || 22}
    viewBox="0 0 23 22"
    className={className}
    {...props}
  >
    <path 
      d="M17.969 6.875h-.043a6.105 6.105 0 00-2.124-3.908A6.64 6.64 0 0011.5 1.4a6.64 6.64 0 00-4.302 1.567 6.105 6.105 0 00-2.124 3.908h-.043c-.953 0-1.867.362-2.54 1.006a3.363 3.363 0 00-1.054 2.431c0 .912.38 1.786 1.053 2.43a3.679 3.679 0 002.541 1.008H6.47V7.562c0-1.276.53-2.5 1.473-3.403A5.15 5.15 0 0111.5 2.75a5.15 5.15 0 013.558 1.41 4.709 4.709 0 011.473 3.403v6.875c0 .63-.227 1.24-.641 1.73-.415.49-.993.827-1.637.958a2.764 2.764 0 00-1.212-1.56 2.981 2.981 0 00-1.986-.404 2.91 2.91 0 00-1.771.953 2.676 2.676 0 00-.67 1.834c.017.67.29 1.31.765 1.8.475.491 1.122.8 1.818.867a2.973 2.973 0 001.963-.499c.57-.39.97-.964 1.129-1.616a4.369 4.369 0 002.627-1.38 4.022 4.022 0 001.053-2.683v-.687c.953 0 1.867-.363 2.54-1.007a3.363 3.363 0 001.053-2.43c0-.913-.378-1.787-1.052-2.432a3.678 3.678 0 00-2.541-1.006zM2.875 10.312c0-.547.227-1.071.632-1.458A2.207 2.207 0 015.03 8.25v4.125c-.572 0-1.12-.218-1.524-.604a2.018 2.018 0 01-.632-1.459zM11.5 19.25c-.284 0-.562-.081-.799-.232a1.39 1.39 0 01-.53-.617 1.32 1.32 0 01-.08-.795c.055-.266.192-.511.393-.704a1.46 1.46 0 01.736-.376c.278-.053.567-.026.83.078s.487.28.645.507c.158.226.242.492.242.764 0 .364-.151.714-.42.972-.27.258-.636.403-1.017.403zm6.469-6.875V8.25c.572 0 1.12.217 1.524.604.405.387.632.911.632 1.458 0 .547-.227 1.072-.631 1.459a2.207 2.207 0 01-1.525.604z"
    />
  </SvgIcon>