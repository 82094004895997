import { ComponentType } from 'react'
import { FormattedMessage, useIntl } from 'react-intl';

interface LanguageHook {
  lang: (title: string | void, values?: Record<string, string>) => string;
  currentLang: string;
  FormattedLang: ComponentType<{ id: string }>;
}

export const useLanguage = (): LanguageHook => {
  const intl = useIntl();
  const lang = (title: string, values?: Record<string, string>) => 
    intl.formatMessage({ id: title }, values);

  const currentLang: string = intl.locale;
  
  const FormattedLang: ComponentType<{ id: string }> = ({ id }) => (
    <FormattedMessage id={id} />
  );

  return { lang, currentLang, FormattedLang };
}